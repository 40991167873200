import './userStyle.css'
import VsTextArea from "@/components/vsTextArea";
import ImageSlider from "@/components/ImageSlider";
import axios from '../router/ApiService'
import alert from '../Alerter'

let model = {
    editActiveLevelUserData: false,
    editFileUserData: false,
    editActiveCardUserData: false,
    editUserId: null,
    edtUserNid: null,
    edtUserPhone: null,
    edtUserIban: null,
    edtUserCardNumber: null,
    edtUserCardId: null,
    edtUserName: null,
    editProp: {},
    search: '',
    LoadingActiveButtonCheckLevel: 0,
    LoadingActiveButtonNotif: 0,
    LoadingActiveButtonCheckNid: 0,
    allCheckUser: false,
    allCheckCard: false,
    page: 1,
    max: 10,
    lengthPage: 1,
    active: 0,
    selectedUser: [],
    selectedCard: [],
    imageIdFile:[],
    users: null,
    activeUser: 0,
    pageItem: [],
    pageItemCard: [],
    selection: [{
        'label': 'سطح 1',
        'id': 0
    },
        {
            'label': 'در انتظار 2',
            'id': 0.5
        },
        {
            'label': 'سطح 2',
            'id': 1
        },
        {
            'label': 'در انتظار سطح 3',
            'id': 1.5
        },
        {
            'label': 'سطح 3',
            'id': 2
        }],
    loadingPage: null,
    swActiveCardUser: false,
    valueNotifData:''
};

export default {
    data: () => (model),
    components: {VsTextArea,ImageSlider},
    name: "userPage",
    async created() {
        this.users = new Map();
        this.openLoading();
        await this.getListUsersApi();
    },
    watch: {
        page: async function () {
            if (this.users.get(this.page + '') === undefined) {
                this.openLoading()
                await this.getListUsersApi()
            } else {
                this.SetPageDataUser(JSON.parse(this.users.get(this.page + '')), 1)
            }
        },
        search: function () {
            this.SetPageDataUser(JSON.parse(this.users.get(this.page + '')), 1)
        }
    },
    methods: {
        openLoading() {
            this.loadingPage = this.$vs.loading({
                type: 'circles',
                color: '#17E8F3'
            })
        },
        SetPageDataUser(dataUser, page) {
            const p = this.$vs.getPage(this.$vs.getSearch(dataUser, this.search), page, this.max)
            this.pageItem.splice(0, this.pageItem.length)
            p.forEach(User => {
                this.pageItem.push(User)
            })
        },
        SetPageDataUserCard(dataUser) {
            this.pageItemCard.splice(0, this.pageItemCard.length)
            dataUser.forEach(User => {
                this.pageItemCard.push(User)
            })
        },
        getEditUserData(tr) {
            this.editUserId = tr.id;
            this.edtUserNid = tr.nid;
            this.edtUserPhone = tr.mobile;
            this.editProp = tr.verifyLevel;
            this.editActiveLevelUserData = true;
            this.valueNotifData="";
        },
        getStatusLevelUser(userLevel) {
            this.activeUser = userLevel
            switch (userLevel) {
                case 0: {
                    return 'سطح 1'
                }
                case 0.5: {
                    return 'در انتظار سطح 2'
                }
                case 1: {
                    return 'سطح 2'
                }
                case 1.5: {
                    return 'در انتظار سطح 3'
                }
                case 2: {
                    return 'سطح 3'
                }
            }
        },
        setColor: function (level) {
            let nameClass = 'userLevel0';
            switch (+level) {
                case 0: {
                    nameClass = 'userLevel0'
                    break;
                }
                case 0.5: {
                    nameClass = 'userLevel05'
                    break;
                }
                case 1: {
                    nameClass = 'userLevel1'
                    break;
                }
                case 1.5: {
                    nameClass = 'userLevel15'
                    break;
                }
                case 2: {
                    nameClass = 'userLevel2'
                    break;
                }
                default: {
                    nameClass = 'userLevel0'
                    break;
                }
            }
            return nameClass;

        },
        setTextNotifInInput:function (level){
            switch (level){
               case 0:{
                   this.valueNotifData='کاربر گرامی\n' +
                       'کد ملی و یا شماره همراه ثبت شده به نام فرد ثبت نام کننده نیست\n' +
                       'لطفا از صحت اطلاعات مطمئن شده و مجددا درخواست سطح 2 را وارد کنید\n ';
                    break;
                }
                case 1:{
                    this.valueNotifData='کاربر گرامی\n' +
                        'عکس سلفی ارسال شده مطابق با راهنمای برنامه نیست\n' +
                        'لطفا عکس سلفی همراه متن دست نوشته به همراه کارت ملی گرفته شود\n' +
                        'و مجددا ارسال نمایید';
                    break;
                }
                case 2:{
                    this.valueNotifData='کاربر گرامی\n' +
                        'عکس سلفی ارسالی واضح نبوده و قابل بررسی نیست\n' +
                        'لطفا عکس را با کیفیت بالاتر مجددا ارسال نمایید';
                    break;
                }
            }
        },
       async sendNotifToUserApi(){
            const form = new URLSearchParams();
            form.append('id', this.editUserId);
            form.append('title', this.$refs.OnTexChangeee.$data.valueInput);
            form.append('message', this.$refs.OnTexChange.$data.valueInput);
            this.LoadingActiveButtonNotif = 1;
            await axios(sessionStorage.token).post('user/sendnotif', form)
                .then((res) => {
                    this.LoadingActiveButtonNotif = 0;
                    alert.openNotification(this.$vs, 'Success', res.data.message, 'success');
                })
                .catch((e) => {
                    this.LoadingActiveButtonNotif = 0;
                    alert.openNotification(this.$vs, 'Error', e.message, 'danger');
                })
        },
        async setRefreshPageUser(){
            this.openLoading();
            this.users.set(this.page + '', undefined);
           await this.getListUsersApi();
        },
        async getCheckCardUser(trUser) {
            this.edtUserIban = trUser.sheba;
            this.edtUserCardNumber = trUser.card;
            await this.getCheckCardUserApi();
        },
        async getActiveCardUser(trUserCard) {
            this.swActiveCardUser = trUserCard.isVerified;
            this.edtUserCardId = trUserCard.id;
            await this.setChangeCardUserStatusApi()
        },
        async getEditCardUser(tr) {
            this.editActiveCardUserData = true;
            this.editUserId = tr.id;
            this.edtUserName = tr.name;
            this.pageItemCard.splice(0, this.pageItemCard.length);
            await this.getListCardUserApi();
        },
        async getListCardUserApi() {
            this.openLoading();
            const form = new URLSearchParams();
            form.append('id', this.editUserId);
            await axios(sessionStorage.token).post('user/cards', form)
                .then((res) => {
                    this.SetPageDataUserCard(res.data.message);
                    this.loadingPage.close();
                })
                .catch((e) => {
                    alert.openNotification(this.$vs, 'Error', e.message, 'danger');
                    this.loadingPage.close();
                });
        },
        async getListUsersApi() {
            const form = new URLSearchParams();
            form.append('page', this.page);
            form.append('limit', this.max);
            await axios(sessionStorage.token).post('user/list', form)
                .then((res) => {
                    if (this.users.get(this.page + '') === undefined) {
                        this.users.set(this.page + '', JSON.stringify(res.data.message.list));
                        this.SetPageDataUser(res.data.message.list, 1);
                    }
                    if (this.lengthPage < parseInt(res.data.message.page)) {
                        this.lengthPage = parseInt(res.data.message.page);
                    }
                    this.loadingPage.close();
                })
                .catch((e) => {
                    alert.openNotification(this.$vs, 'Error', e.message, 'danger');
                    this.loadingPage.close();
                })
        },
        async setChangeUserLevelAccessApi() {
            const form = new URLSearchParams();
            form.append('id', this.editUserId);
            form.append('step', this.editProp);
            form.append('message', this.$refs.OnTexChange.$data.valueInput);
            this.LoadingActiveButtonCheckLevel = 1;
            await axios(sessionStorage.token).post('user/verifylevel', form)
                .then((res) => {
                    this.LoadingActiveButtonCheckLevel = 0;
                    this.editActiveLevelUserData = false;
                    let userItem = this.pageItem.find(dataFind => dataFind.id === this.editUserId)
                    userItem.verifyLevel = this.editProp
                    alert.openNotification(this.$vs, 'Success', res.data.message, 'success');
                })
                .catch((e) => {
                    this.LoadingActiveButtonCheckLevel = 0;
                    alert.openNotification(this.$vs, 'Error', e.message, 'danger');
                })
        },
        async getCheckNidPhoneUserApi() {
            this.LoadingActiveButtonCheckNid = 1;
            await axios(undefined, 'https://api.intex.finance/api/v1/').get('inquiry/MatchNidAndMobile?nid=' + this.edtUserNid + '&mobile=0' + this.edtUserPhone + '')
                .then((res) => {
                    this.LoadingActiveButtonCheckNid = 0;
                    if (res.data.message.matched) {
                        alert.openNotification(this.$vs, 'Success', 'شماره همراه با کد ملی مطابقت دارد', 'success');
                    } else {
                        alert.openNotification(this.$vs, 'Error', 'شماره همراه با کد ملی مطابقت ندارد', 'danger');
                    }
                })
                .catch((e) => {
                    this.LoadingActiveButtonCheckNid = 0;
                    alert.openNotification(this.$vs, 'Error', e.message, 'danger');
                })
        },
        async getCheckCardUserApi() {
            this.openLoading();
            await axios(undefined, 'https://api.intex.finance/api/v1/').get('inquiry/bankCardDetails?card=' + this.edtUserCardNumber + '')
                .then((res) => {
                    this.loadingPage.close();
                    if (res.data.message) {
                        let active = res.data.message.ibanInfo.status;
                        let iban = res.data.message.ibanInfo.iban.substring(2);
                        let name = res.data.message.ibanInfo.owners[0].firstName + ' ' + res.data.message.ibanInfo.owners[0].lastName
                        if (active !== 'ACTIVE') {
                            alert.openNotification(this.$vs, 'Error', 'شماره کارت فعال نمی باشد', 'danger');
                        } else {
                            if (iban !== this.edtUserIban) {
                                alert.openNotification(this.$vs, 'Error', 'شبا همخوانی ندارد' + '  ' + iban, 'danger');
                            } else {
                                alert.openNotification(this.$vs, active, name + '  ' + '       ' + iban, 'warn');
                            }
                        }
                    } else {
                        alert.openNotification(this.$vs, 'Error', 'شماره کارت و شماره شبا یافت نشد', 'danger');
                    }
                })
                .catch((e) => {
                    this.loadingPage.close();
                    alert.openNotification(this.$vs, 'Error', e.message, 'danger');
                })

        },
        async setChangeCardUserStatusApi() {
            this.openLoading();
            const form = new URLSearchParams();
            form.append('id', this.editUserId);
            form.append('cid', this.edtUserCardId);
            form.append('active', this.swActiveCardUser ? 0 : 1);
            await axios(sessionStorage.token).post('user/verifycard',form)
                .then((res) =>{
                    this.loadingPage.close();
                    alert.openNotification(this.$vs, 'Success', res.data.message, 'success');
                })
                .catch((e) => {
                    this.loadingPage.close();
                    let cardItem = this.pageItemCard.find(dataFind => dataFind.id === this.editUserId)
                    cardItem.isVerified = !cardItem.isVerified
                    alert.openNotification(this.$vs, 'Error', e.message, 'danger');
                })
        },
        async getImageUserFileIde(){
            this.openLoading();
            const form = new URLSearchParams();
            form.append('id', this.editUserId);
            await axios(sessionStorage.token).post('user/files',form)
                .then(async (res) =>{
                    this.imageIdFile.splice(0,this.imageIdFile.length)
                    for (const item of res.data.message){
                        try {
                            let res = await axios().get('user/file?token=' + sessionStorage.token + '&id=' + item.id + '')
                            this.imageIdFile.push(res.data.message);
                        } catch (e) {
                            this.imageIdFile.push('');
                        }
                    }
                    if (this.imageIdFile.length>0){
                        this.editFileUserData=true;
                    }else{
                        alert.openNotification(this.$vs, 'Error', 'هیچ عکسی یافت نشد', 'danger');
                    }
                    this.loadingPage.close();
                })
                .catch((e) => {
                    this.loadingPage.close();
                    alert.openNotification(this.$vs, 'Error', e.message, 'danger');
                });
        },
    }
}