<template>
  <div
       class="vs-input-parent vs-input-parent--state-null vs-input-parent--shadow vs-input-content--has-label vs-component--success vs-component--is-color"
       id="vs-input_notif">
    <div class="vs-input-content vs-input-content--has-color vs-input-content--has-label">
      <textarea
          id="vs-input--vs-input_notif" v-model="valueInput"   placeholder="" class="vs-input vs-input--has-icon vs-input--vs-input_notif"></textarea>
      <label
        for="vs-input--vs-input_notif" class="vs-input__label vs-input__label--placeholder">{{valueLabelText}}</label><span
        class="vs-input__icon"><i  class="bx bxs-notification"></i></span>
      <div class="vs-input__affects">
        <div class="vs-input__affects__1"></div>
        <div class="vs-input__affects__2"></div>
        <div class="vs-input__affects__3"></div>
        <div class="vs-input__affects__4"></div>
      </div>
    </div><!----><!----><!----><!----></div>
</template>

<script>
export default {
  data:()=>({
    valueInput:''
  }),
  props: {
    valueInputData: String,
    valueLabel:String
  },
  watch:{
    valueInputData:function (){
      this.valueInput=this.valueInputData
    }
  }
  ,
  name: "vsTextArea",
  computed:{
    valueLabelText:function (){
      return this.valueLabel;
    }
  }
}
</script>

<style scoped>

</style>